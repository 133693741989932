import fetch from 'cross-fetch';
import { ErrorResponse, LoginStatusResponse } from '../response';
import { sameOriginRequestPolicy } from '../actions';
import { LoginStatusAction } from './login-status';

/**
 * Checks if user is already logged in
 */
export class RemoteLoginStatusAction {
	private readonly baseUrl: string | null;

	constructor(baseUrl: string | null) {
		this.baseUrl = baseUrl;
	}

	action: LoginStatusAction = async (): Promise<LoginStatusResponse | ErrorResponse> => {
		const base: string = this.baseUrl || '';

		try {
			const response: Response = await fetch(`${base}/api/login/status`, {
				...sameOriginRequestPolicy,
				method: 'GET',
				headers: {
					'Content-Type': 'application/json'
				},
			});

			return response.json();
		} catch (e: unknown) {
			throw new Error('unexpected');
		}
	}
}
