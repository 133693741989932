import {RedirectAction} from '../actions';
import {ErrorResponse, RedirectResponse} from '../response';
import {mockedErrorResponseGameOffline, mockedFaultyRedirectResponse, mockedRedirectResponse} from '../mocks';

const successRedirectMock: RedirectAction<undefined> = async (): Promise<RedirectResponse | ErrorResponse> => {
	return Promise.resolve(mockedRedirectResponse);
};

const failRedirectMock: RedirectAction<undefined> = async (): Promise<RedirectResponse | ErrorResponse> => {
	return Promise.resolve(mockedFaultyRedirectResponse);
};

const failErrorMock: RedirectAction<undefined> = async (): Promise<RedirectResponse | ErrorResponse> => {
	return Promise.resolve(mockedErrorResponseGameOffline);
};

/**
 * Set of all play action mocks
 */
export const playMocks: {
	successRedirect: RedirectAction<undefined>;
	failRedirect: RedirectAction<undefined>;
	failError: RedirectAction<undefined>;
} = {
	successRedirect: successRedirectMock,
	failRedirect: failRedirectMock,
	failError: failErrorMock
};
