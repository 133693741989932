import fetch from 'cross-fetch';
import {ErrorResponse, RedirectResponse} from '../response';
import {RedirectAction, sameOriginRequestPolicy} from '../actions';

/**
 * Validation against backend via {@link fetch}
 */
export class RemoteLogoutAction {
	private readonly baseUrl: string | null;

	constructor(baseUrl: string | null) {
		this.baseUrl = baseUrl;
	}

	action: RedirectAction<undefined> = async (): Promise<RedirectResponse | ErrorResponse> => {
		const base: string = this.baseUrl || '';

		try {
			const response: Response = await fetch(`${base}/api/logout`, {
				...sameOriginRequestPolicy,
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
			});

			return response.json();
		} catch (e: unknown) {
			throw new Error('unexpected');
		}
	}
}
