import fetch from 'cross-fetch';
import {
	PasswordForgottenVerifyAction,
	PasswordForgottenVerifyInformation,
	ResetPasswordAction,
	ResetPasswordRequest,
	ResetPasswordResponse,
} from './password-forgotten';
import {ErrorResponse, PasswordForgottenVerifyResponse,} from '../response';
import {addLPSLocaleHeader, sameOriginRequestPolicy} from '../actions';
import {RequiredBodyError} from '../error';

/**
 * Forgot PW: verify user's email or username, change password
 */
export class RemotePasswordForgottenActions {
	private readonly baseUrl: string | null;

	constructor(baseUrl: string | null) {
		this.baseUrl = baseUrl;
	}

	verifyAction: PasswordForgottenVerifyAction = async (playerIdentification: PasswordForgottenVerifyInformation, locale: string): Promise<PasswordForgottenVerifyResponse | ErrorResponse> => {
		const base: string = this.baseUrl || '';

		if (!playerIdentification) {
			throw new RequiredBodyError();
		}

		try {
			const response: Response = await fetch(`${base}/api/forgotpw`, {
				...sameOriginRequestPolicy,
				method: 'POST',
				headers: addLPSLocaleHeader({'Content-Type': 'application/json'}, locale),
				body: JSON.stringify(playerIdentification)
			});

			return response.json();
		} catch (e: unknown) {
			throw new Error('unexpected');
		}
	};

	resetAction: ResetPasswordAction = async (payload: ResetPasswordRequest, locale: string): Promise<ResetPasswordResponse | ErrorResponse> => {
		const base: string = this.baseUrl || '';

		if (!payload) {
			throw new RequiredBodyError();
		}

		try {
			const response: Response = await fetch(`${base}/api/forgotpw/reset`, {
				...sameOriginRequestPolicy,
				method: 'POST',
				headers: addLPSLocaleHeader({'Content-Type': 'application/onelps.reset.v2+json'}, locale),
				body: JSON.stringify(payload)
			});

			return response.json();
		} catch (e: unknown) {
			throw new Error('unexpected');
		}
	};
}
