import {mockedErrorResponseGameOffline, mockedFaultyRedirectResponse, mockedRedirectResponse} from '../mocks';
import {LoginRequest} from './login';
import {ErrorResponse, RedirectResponse} from '../response';
import {RedirectAction} from '../actions';

const successRedirectMock: RedirectAction<LoginRequest> = async (): Promise<RedirectResponse | ErrorResponse> => {
	return Promise.resolve(mockedRedirectResponse);
};

const failRedirectMock: RedirectAction<LoginRequest> = async (): Promise<RedirectResponse | ErrorResponse> => {
	return Promise.resolve(mockedFaultyRedirectResponse);
};

const failErrorMock: RedirectAction<LoginRequest> = async (): Promise<RedirectResponse | ErrorResponse> => {
	return Promise.resolve(mockedErrorResponseGameOffline);
};

/**
 * Set of all available mocks
 */
export const loginMocks: {
	successRedirect: RedirectAction<LoginRequest>;
	failRedirect: RedirectAction<LoginRequest>;
	failError: RedirectAction<LoginRequest>;
} = {
	successRedirect: successRedirectMock,
	failRedirect: failRedirectMock,
	failError: failErrorMock
};
