import {Config, Environment} from '../config';
import {RemoteValidateAction, ValidateAction, validateMocks} from './validation';
import {RegisterAction, registrationMocks, RemoteRegisterAction} from './registration';
import {ErrorResponse, RedirectResponse} from './response';
import {loginMocks, LoginRequest, RemoteLoginAction} from './login';
import {playMocks, RemotePlayAction} from './play';
import {logoutMocks, RemoteLogoutAction} from './logout';
import {LoginStatusAction, loginStatusMocks, RemoteLoginStatusAction} from './login-status';
import {
	RemoteTranslationAction,
	TransformationAction,
	TransformTranslationAction,
	transformTranslationMocks,
	TranslationAction,
	translationMocks,
} from './translation';
import {
	passwordForgottenResetMocks,
	PasswordForgottenVerifyAction,
	passwordForgottenVerifyMocks,
	RemotePasswordForgottenActions,
	ResetPasswordAction,
} from './password-forgotten';
import {isStringBlank} from '../utils';

/**
 * Same site request policy to be used by all actions
 */
export const sameOriginRequestPolicy: RequestInit = {
	mode: 'same-origin',
	credentials: 'same-origin',
	referrerPolicy: 'same-origin'
};

export const addLPSLocaleHeader = (headers: HeadersInit, locale = 'en_DK'): HeadersInit => {
	return {...headers, 'X-LPS-locale': isStringBlank(locale) ? 'en_DK' : locale};
};

/**
 * Redirect action sending a generic payload.
 */
export type RedirectAction<T> = (payload?: T) => Promise<RedirectResponse | ErrorResponse>;

export interface Actions {
	validate: ValidateAction;
	register: RegisterAction;
	login: RedirectAction<LoginRequest>;
	play: RedirectAction<undefined>;
	logout: RedirectAction<undefined>;
	loginStatus: LoginStatusAction;
	translation: TranslationAction;
	transformTranslation: TransformationAction;
	passwordForgottenVerify: PasswordForgottenVerifyAction;
	resetPassword: ResetPasswordAction;
}

export const mockedActions = {
	validate: validateMocks,
	register: registrationMocks,
	login: loginMocks,
	play: playMocks,
	logout: logoutMocks,
	loginStatus: loginStatusMocks,
	translation: translationMocks,
	transformTranslation: transformTranslationMocks,
	passwordForgottenVerify: passwordForgottenVerifyMocks,
	resetPassword: passwordForgottenResetMocks,
};

export class ActionsBuilder {
	build(config: Config, env: Environment = Environment.DEVELOPMENT): Actions {
		const defaultActions = {
			[Environment.DEVELOPMENT]: () => ({
				validate: mockedActions.validate.success,
				register: mockedActions.register.registerSuccess,
				login: mockedActions.login.successRedirect,
				play: mockedActions.play.successRedirect,
				logout: mockedActions.logout.successRedirect,
				loginStatus: mockedActions.loginStatus.successLoginStatus,
				translation: mockedActions.translation.successTranslation,
				transformTranslation: mockedActions.transformTranslation.successTransformation,
				passwordForgottenVerify: mockedActions.passwordForgottenVerify.successVerification,
				resetPassword: mockedActions.resetPassword.successfulReset,
			}),
			[Environment.PRODUCTION]: () => ({
				validate: new RemoteValidateAction(config.getBaseUrl()).action,
				register: new RemoteRegisterAction(config.getBaseUrl()).action,
				login: new RemoteLoginAction(config.getBaseUrl()).action,
				play: new RemotePlayAction(config.getBaseUrl()).action,
				logout: new RemoteLogoutAction(config.getBaseUrl()).action,
				loginStatus: new RemoteLoginStatusAction(config.getBaseUrl()).action,
				translation: new RemoteTranslationAction(config.getBaseUrl()).action,
				transformTranslation: new TransformTranslationAction().action,
				passwordForgottenVerify: new RemotePasswordForgottenActions(config.getBaseUrl()).verifyAction,
				resetPassword: new RemotePasswordForgottenActions(config.getBaseUrl()).resetAction,
			})
		};

		return defaultActions[env]();
	}
}
