import { RuntimeConfig } from './config';
import { OneLPS } from './onelps';

declare const window: {
  ONELPS_RUNTIME_CONFIG: RuntimeConfig
};

const onelps = new OneLPS();

if (typeof window !== 'undefined') {
	if (window && window.ONELPS_RUNTIME_CONFIG) {
		onelps.initRuntime(window.ONELPS_RUNTIME_CONFIG);
	}
}

export default onelps;
