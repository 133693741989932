import { mockedErrorResponseGameOffline, mockedLoginStatusResponse } from '../mocks';
import { ErrorResponse, LoginStatusResponse } from '../response';
import { LoginStatusAction } from './login-status';

const successLoginStatusMock: LoginStatusAction = async () : Promise<LoginStatusResponse | ErrorResponse> => {
	return Promise.resolve(mockedLoginStatusResponse);
};

const failErrorMock: LoginStatusAction = async (): Promise<LoginStatusResponse | ErrorResponse> => {
	return Promise.resolve(mockedErrorResponseGameOffline);
};

export const loginStatusMocks: {
	successLoginStatus: LoginStatusAction;
	failError: LoginStatusAction;
} = {
	successLoginStatus: successLoginStatusMock,
	failError: failErrorMock
};
