import { RegisterAction, RegisterResponseSuccess } from './registration';
import { ValidationErrorResponse } from '../response';
import {Error} from '../error';

export const registerSuccessMock: RegisterAction = async (): Promise<RegisterResponseSuccess> => {
	const result: RegisterResponseSuccess = {
		playerId: 123456,
		redirectUrl: '/login-successful',
	};

	return Promise.resolve(result);
};

export const registerValidationErrorMock: RegisterAction = async (): Promise<ValidationErrorResponse> => {
	const result: ValidationErrorResponse = {
		errors: new Map<string, Error[]>([
			['nickname', [ {code: 'be.api.portal.nickname_too_short', message: 'Nickname is too short'}]]
		]),
		nicknameSuggestions: [
			'Suggestion01',
			'Suggestion02',
			'Suggestion03',
			'Suggestion04',
			'Suggestion05'
		]
	};

	return Promise.resolve(result);
};

/**
 * Set of all available mocks
 */
export const registrationMocks: {
	// V2
	registerSuccess: RegisterAction;
	registerValidationError: RegisterAction;
} = {
	registerSuccess: registerSuccessMock,
	registerValidationError: registerValidationErrorMock,
};
