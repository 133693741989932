import {Base64} from 'js-base64';
import {isMessage, Message} from './types';

const messagesCookieName = '__Host-messages';

/**
 * Reads in the message from the queue cookie.
 *
 * @returns the messages which were queued up
 */
const readMessagesCookie = (): Message[] => {
	if (typeof document === 'undefined' || !document.cookie) {
		return [];
	}

	try {
		const cookies = document.cookie.split('; ');
		let decodedValue: string | undefined = undefined;

		for (let i = 0; i < cookies.length; i++) {
			const parts = cookies[i].split('=');
			if (parts.length < 2) {
				continue;
			}

			const name = decodeURIComponent(parts[0]);

			if (name !== messagesCookieName) {
				continue;
			}

			// Re-add padding from Base64 to value
			const value = parts.slice(1).join('=');
			if (!Base64.isValid(value)) {
				continue;
			}

			decodedValue = Base64.decode(value);

			break;
		}

		if (!decodedValue) {
			return [];
		}

		const messages: Message[] = JSON.parse(decodedValue).messages ?? [];

		return messages.filter(m => isMessage(m));
	} catch (e: unknown) {
		return [];
	}
};

/**
 * Writes messages back to the queue cookie.
 * They can then be picked up later in the page life cycle.
 *
 * @param messages which should be written into the cookie
 * @returns the updated cookies returned from document.cookies or undefined if it was executed outside a browser environment
 */
const writeMessagesCookie = (messages: Message[]): string | undefined => {
	if (typeof document === 'undefined') {
		return;
	}

	try {
		const base64EncodedValue = Base64.encode(JSON.stringify({messages}));
		const name = encodeURIComponent(messagesCookieName);

		// Ones read on FE it transfers to a session cookie as we don't know when we will handle the remaining messages
		return (document.cookie = `${name}=${base64EncodedValue}; path=/; secure; samesite=strict`);
	} catch (e: unknown) {
		return undefined;
	}
};

/**
 * Gets all messages from the queue cookie to display to the user.
 * Writes an empty structure back into the cookie to signal that all messages where handled.
 *
 * @returns the messages which were queued in the cookie
 */
export const getAllMessagesFromQueueCookie = (): Message[] => {
	const messages = readMessagesCookie();
	writeMessagesCookie([]);

	return messages;
};

/**
 * Gets the next messages from the queue cookie to display to the user.
 * Writes all not yet displayed messages back into the cookie so it can be handled later in time.
 *
 * @param context optional parameter specifying of which context the next message should be loaded
 * @returns the next message in line or undefined if there are no messages in general or for the specified context
 */
export const getNextMessageFromQueueCookie = (context?: string): Message | undefined => {
	const messages = readMessagesCookie();

	if (context) {
		const i = messages.findIndex(m => m.context === context);

		if (i < 0) {
			// No fitting message was found, so also no write back is happening, as no message was read from the cookie
			return undefined;
		}

		const message = messages[i];
		messages.splice(i, 1);
		writeMessagesCookie(messages);

		return message;
	}

	const message = messages.shift();
	writeMessagesCookie(messages);

	return message;
};
