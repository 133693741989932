import {ErrorResponse, PasswordForgottenVerifyResponse} from '../response';
import {Error} from '../error';

export type PasswordForgottenVerifyAction = (playerIdentification: PasswordForgottenVerifyInformation, locale: string) => Promise<PasswordForgottenVerifyResponse | ErrorResponse>;
export type ResetPasswordAction = (payload: ResetPasswordRequest, locale: string) => Promise<ResetPasswordResponse | ErrorResponse>;

export type PasswordForgottenVerifyInformation = {
	playerIdentification: string;
};

export type ResetPasswordRequest = {
	token: string;
	password: string;
};

export type ResetPasswordResponse = ResetPasswordSuccess | ResetPasswordError

// empty object, {}
export type ResetPasswordSuccess = Record<PropertyKey, never>;


export type PasswordForgottenVerifyErrorCodes = 'be.api.fgpw.playerParamIncorrect' | 'be.api.fgpw.playerNotFound' | 'be.api.fgpw.emailNotConfirmed';
export type ResetPasswordErrorCodes = 'be.api.fgpw.tokenInvalid' | 'be.api.fgpw.tokenExpired' | 'be.api.fgpw.tokenUsed'


export type ResetPasswordError = {
	errors: Error[],
};
