import {ValidationErrorResponse} from '../response';

export type ValidateAction = (request: ValidateRequest, locale: string) => Promise<ValidateResponse>;
export type ValidateResponse = ValidateResponseSuccess | ValidationErrorResponse;

export interface ValidateRequest {
	nickname: string | null,
	email: string | null,
	password: string | null,
}

// empty object, {}
export type ValidateResponseSuccess = Record<PropertyKey, never>;


