import {
	ErrorResponse,
	LoginStatusResponse,
	PasswordForgottenVerifyResponse,
	RedirectResponse,
	ResponseType,
	TransformTranslationResponse,
	TranslationResponse,
} from './response';
import {ResetPasswordError, ResetPasswordSuccess} from './password-forgotten';


// Redirect mocks
export const mockedRedirectResponse: { redirectUrl: string; playerId: number } = {
	playerId: 123456,
	redirectUrl: 'https://riseofculture.com'
};

export const mockedFaultyRedirectResponse: RedirectResponse = {
	errors: [{
		code: 'be.api.auth.login-failed-id',
		message: 'There is no player with this name. Please try again.'
	}],
};

// Login status mocks
export const mockedLoginStatusResponse: LoginStatusResponse = {
	isAuthenticated: false,
	playerId: -1,
	username: 'PinkPanther',
};

// Translation mocks
export const mockedTranslationResponse: TranslationResponse = {
	locale: 'en_DK',
	translations: [
		{
			k: 'twoStepForm.header.Step1',
			v: 'Play now!'
		},
		{
			k: 'twoStepForm.header.Step2',
			v: 'Almost finished!'
		},
	],
};

export const mockedTranslationErrorResponse: TranslationResponse = {
	errors: [{
		code: 'be.api.lps.error',
		message: 'locale not found'
	}],
};

// PWF mocks
export const mockedPasswordForgottenVerifyResponse: PasswordForgottenVerifyResponse = {
	result: 'ok',
};

export const mockedPasswordForgottenVerifyErrorResponse: PasswordForgottenVerifyResponse = {
	result: 'error',
	errors: [{
		code: 'be.api.fgpw.playerParamIncorrect',
		message: 'Player parameter is incorrect'
	}],
};

export const mockedPasswordForgottenResetResponse: ResetPasswordSuccess = {};

export const mockedPasswordForgottenResetVerificationErrorResponse: ResetPasswordError = {
	errors: [
		{
			code: 'be.api.portal.password_too_short',
			message: 'Your entry is too short (minimum \'6\' characters)',
		}
	]
};

export const mockedPasswordForgottenChangeTokenErrorResponse: ResetPasswordError = {
	errors: [
		{
			code: 'be.api.fgpw.tokenExpired',
			message: 'URL is expired',
		}
	]
};

// Translation transformation mocks
export const mockedTransformTranslationResponse: TransformTranslationResponse = {
	'en': {
		'translation': {
			'twoStepForm.header.Step1': 'Play now!',
			'twoStepForm.header.Step2': 'Almost finished!',
			'twoStepForm.ctaButton.Step1': 'Continue',
			'twoStepForm.ctaButton.Step2': 'Play',
			'twoStepForm.ipp.caption': 'Or register with',
			'twoStepForm.username.label': 'Choose your username',
			'twoStepForm.password.label': 'Choose your password',
			'twoStepForm.email.label': 'Enter your E-mail',
			'twoStepForm.username.hint1':'Use between 4 and 24 characters',
			'twoStepForm.username.hint2': 'Avoid special characters',
		}
	}
};

// Error response mocks
export const mockedErrorResponseGameOffline: ErrorResponse = {
	type: ResponseType.ERROR,
	data: {
		code: 'game_offline',
		message: {
			en: 'The game is in maintenance right now. Please try again later.',
			translated: 'The game is in maintenance right now. Please try again later.'
		},
		additionalInfo: {}
	},
};
