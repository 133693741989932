import {TransformTranslationResponse, TranslationResponse} from '../response';

export type TransformationAction = (backendTranslationResponse: TranslationResponse) => TransformTranslationResponse | undefined;

export class TransformTranslationAction {
	action: TransformationAction = (backendResponse: TranslationResponse): TransformTranslationResponse | undefined => {
		if (!backendResponse.locale || !backendResponse.translations) {
			return undefined;
		}

		const language = backendResponse.locale.slice(0, 2);

		const translationKeyValuePairs = backendResponse.translations.reduce((accum, current) => {
			return {...accum, [current.k]: current.v};
		}, {});


		return {
			[language]: {
				'translation': translationKeyValuePairs
			}
		};
	};
}
