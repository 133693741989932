import fetch from 'cross-fetch';
import {LoginRequest} from './login';
import {ErrorResponse, RedirectResponse} from '../response';
import {RedirectAction, sameOriginRequestPolicy} from '../actions';
import {RequiredBodyError} from '../error';

/**
 * Login in an user on a landing page
 */
export class RemoteLoginAction {
	private readonly baseUrl: string | null;

	constructor(baseUrl: string | null) {
		this.baseUrl = baseUrl;
	}

	action: RedirectAction<LoginRequest> = async (loginRequest?: LoginRequest): Promise<RedirectResponse | ErrorResponse> => {
		const base: string = this.baseUrl || '';

		if (!loginRequest) {
			throw new RequiredBodyError();
		}

		try {
			const response: Response = await fetch(`${base}/api/login`, {
				...sameOriginRequestPolicy,
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(loginRequest)
			});

			return response.json();
		} catch (e: unknown) {
			throw new Error('unexpected');
		}
	}
}
