import { Actions, ActionsBuilder } from './actions';
import { Config, DesignParams, SdkConfig, RuntimeConfig } from './config';

/**
 * Doc Placeholder
 */
export class OneLPS {
	private _config: Config
	private _designParams: DesignParams
	private _actions: Actions

	constructor() {
		this._config = new Config({});
		this._designParams = new DesignParams({});
		this._actions = new ActionsBuilder().build(this._config);
	}

	init(sdkConfig: SdkConfig): void {
		this._actions = new ActionsBuilder().build(this._config, sdkConfig.mode);
	}

	initRuntime(runtimeConfig: RuntimeConfig): void {
		this._config = new Config(runtimeConfig.config);
		this._designParams = new DesignParams(runtimeConfig.designParams);
	}

	get config(): Config {
		return this._config;
	}

	set config(value: Config) {
		this._config = value;
	}

	public get designParams(): DesignParams {
		return this._designParams;
	}

	public set designParams(value: DesignParams) {
		this._designParams = value;
	}

	public get actions(): Actions {
		return this._actions;
	}
}
