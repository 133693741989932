import {Error, RichError} from './error';

export enum ResponseType {
	REDIRECT = 'redirect',
	ERROR = 'error',
	OK = 'ok',
}

/**
 * @deprecated
 * Please don't use this response interface for new actions or responses.
 * Only existing actions will return this structure.
 * We agreed on a simpler interface and structure based on plain DTOs.
 */
export interface Response {
	type: string;
	data: Record<string, unknown> | null
}

export interface RedirectResponse {
	playerId?: number,
	redirectUrl?: string;
	errors?: Error[];
}

export interface LoginStatusResponse {
	isAuthenticated: boolean;
	username: string;
	playerId: number;
	errors?: Error[];
}

export interface TranslationResponse {
	locale?: string;
	translations?: {
		k: string;
		v: string;
	}[];
	errors?: Error[];
}

export interface TransformTranslationResponse {
	[locale: string]: {
		translation: {
			[key: string]: string
		}
	};
}

export interface PasswordForgottenVerifyResponse {
	result: string;
	errors?: Error[];
}

export interface ErrorResponse extends Response {
	type: ResponseType.ERROR;
	data: RichError;
}

export function isErrorResponse<T>(response: ErrorResponse | T): response is ErrorResponse {
	return (response as ErrorResponse).type === ResponseType.ERROR;
}

// V2 registration & validation

export class ValidationErrorResponse {
	errors: Map<string, Error[]>
	nicknameSuggestions: string[] | null

	constructor(errors: Map<string, Error[]>, nicknameSuggestions: string[] | null) {
		this.errors = errors;
		this.nicknameSuggestions = nicknameSuggestions;
	}
}
