import {ResetPasswordAction, PasswordForgottenVerifyAction, ResetPasswordResponse} from './password-forgotten';
import {ErrorResponse, PasswordForgottenVerifyResponse} from '../response';
import {
	mockedErrorResponseGameOffline,
	mockedPasswordForgottenChangeTokenErrorResponse,
	mockedPasswordForgottenResetResponse,
	mockedPasswordForgottenResetVerificationErrorResponse,
	mockedPasswordForgottenVerifyErrorResponse,
	mockedPasswordForgottenVerifyResponse,
} from '../mocks';

// PWF verify mocks
const successVerificationMock: PasswordForgottenVerifyAction = async () : Promise<PasswordForgottenVerifyResponse | ErrorResponse> => {
	return Promise.resolve(mockedPasswordForgottenVerifyResponse);
};

const failedVerificationMock: PasswordForgottenVerifyAction = async () : Promise<PasswordForgottenVerifyResponse | ErrorResponse> => {
	return Promise.resolve(mockedPasswordForgottenVerifyErrorResponse);
};

const verifyGeneralFailError: PasswordForgottenVerifyAction = async (): Promise<PasswordForgottenVerifyResponse | ErrorResponse> => {
	return Promise.resolve(mockedErrorResponseGameOffline);
};

// PWF reset mocks
const successfulResetMock: ResetPasswordAction = async () : Promise<ResetPasswordResponse | ErrorResponse> => {
	return Promise.resolve(mockedPasswordForgottenResetResponse);
};

const verificationFailedResetMock: ResetPasswordAction = async () : Promise<ResetPasswordResponse | ErrorResponse> => {
	return Promise.resolve(mockedPasswordForgottenResetVerificationErrorResponse);
};

const tokenFailedResetMock: ResetPasswordAction = async () : Promise<ResetPasswordResponse | ErrorResponse> => {
	return Promise.resolve(mockedPasswordForgottenChangeTokenErrorResponse);
};

const resetGeneralFailError: ResetPasswordAction = async () : Promise<ResetPasswordResponse | ErrorResponse> => {
	return Promise.resolve(mockedErrorResponseGameOffline);
};

export const passwordForgottenVerifyMocks: {
	successVerification: PasswordForgottenVerifyAction;
	failedVerification: PasswordForgottenVerifyAction;
	generalFailError: PasswordForgottenVerifyAction;
} = {
	successVerification: successVerificationMock,
	failedVerification: failedVerificationMock,
	generalFailError: verifyGeneralFailError,
};

export const passwordForgottenResetMocks: {
	successfulReset: ResetPasswordAction;
	verificationFailedReset: ResetPasswordAction;
	generalFailError: ResetPasswordAction;
	tokenFailedReset: ResetPasswordAction;
} = {
	successfulReset: successfulResetMock,
	verificationFailedReset: verificationFailedResetMock,
	generalFailError: resetGeneralFailError,
	tokenFailedReset: tokenFailedResetMock,
};
