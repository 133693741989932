import {Response, ResponseType} from '../response';
import {Error} from '../error';
import { ValidationErrorResponse } from '../response';

/**
 * @deprecated
 * Please don't use this redirect response for new actions.
 * We agreed on a simpler structure.
 * Existing actions will still return this redirect response.
 */
export interface RedirectResponse extends Response {
	type: ResponseType.REDIRECT;
	data: {
		playerId: number,
		redirectUrl: string;
	}
}

// V2 Objects:

export type RegisterAction = (request: RegisterRequest, locale: string) => Promise<RegisterResponse>

export type OnyxRaceOption = 'humans' | 'elves'

export interface RegisterRequest {
	type: string,
	nickname: string | null,
	email: string | null,
	password: string,
	acceptTermsAndConditions: boolean,
	acceptThirdPartyPixels: boolean,
	onyxRaceOption: OnyxRaceOption | null,
	friendInvite: string | null,
}

export class RegisterRequestFull implements RegisterRequest {
	type: string;
	nickname: string;
	email: string;
	password: string;
	acceptTermsAndConditions: boolean;
	acceptThirdPartyPixels: boolean;
	onyxRaceOption: OnyxRaceOption | null;
	friendInvite: string | null;

	constructor(nickname: string, email: string, password: string, acceptTermsAndConditions: boolean, acceptThirdPartyPixels: boolean, onyxRaceOption: OnyxRaceOption | null = null, friendInvite: string | null = null) {
		this.type = 'full';
		this.nickname = nickname;
		this.email = email;
		this.password = password;
		this.acceptTermsAndConditions = acceptTermsAndConditions;
		this.acceptThirdPartyPixels = acceptThirdPartyPixels;
		this.onyxRaceOption = onyxRaceOption;
		this.friendInvite = friendInvite;
	}
}

export class RegisterRequestNoNickname implements RegisterRequest {
	type: string;
	nickname = null;
	email: string;
	password: string;
	acceptTermsAndConditions: boolean;
	acceptThirdPartyPixels: boolean;
	onyxRaceOption: OnyxRaceOption | null;
	friendInvite: string | null;

	constructor(email: string, password: string, acceptTermsAndConditions: boolean, acceptThirdPartyPixels: boolean, onyxRaceOption: OnyxRaceOption | null = null, friendInvite: string | null = null) {
		this.type = 'no_nickname';
		this.email = email;
		this.password = password;
		this.acceptTermsAndConditions = acceptTermsAndConditions;
		this.acceptThirdPartyPixels = acceptThirdPartyPixels;
		this.onyxRaceOption = onyxRaceOption;
		this.friendInvite = friendInvite;
	}
}

export class RegisterRequestNoEmail implements RegisterRequest {
	type: string;
	nickname: string;
	email = null;
	password: string;
	acceptTermsAndConditions: boolean;
	acceptThirdPartyPixels: boolean;
	onyxRaceOption: OnyxRaceOption | null;
	friendInvite: string | null;

	constructor(nickname: string, password: string, acceptTermsAndConditions: boolean, acceptThirdPartyPixels: boolean, onyxRaceOption: OnyxRaceOption | null = null, friendInvite: string | null = null) {
		this.type = 'no_email';
		this.nickname = nickname;
		this.password = password;
		this.acceptTermsAndConditions = acceptTermsAndConditions;
		this.acceptThirdPartyPixels = acceptThirdPartyPixels;
		this.onyxRaceOption = onyxRaceOption;
		this.friendInvite = friendInvite;
	}
}


export class RegisterResponseSuccess {
	playerId: number
	redirectUrl: string

	constructor(playerId: number, redirectUrl: string) {
		this.playerId = playerId;
		this.redirectUrl = redirectUrl;
	}
}

export type RegisterResponse = RegisterResponseSuccess | ValidationErrorResponse
