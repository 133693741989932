import {ErrorResponse, TransformTranslationResponse, TranslationResponse} from '../response';
import {TranslationAction} from './translation';
import {mockedErrorResponseGameOffline, mockedTransformTranslationResponse, mockedTranslationErrorResponse, mockedTranslationResponse} from '../mocks';
import {TransformationAction} from './transformations';

const successTranslationMock: TranslationAction = async () : Promise<TranslationResponse | ErrorResponse> => {
	return Promise.resolve(mockedTranslationResponse);
};

const failedTranslationMock: TranslationAction = async () : Promise<TranslationResponse | ErrorResponse> => {
	return Promise.resolve(mockedTranslationErrorResponse);
};

const translationGeneralFailErrorMock: TranslationAction = async () : Promise<TranslationResponse | ErrorResponse> => {
	return Promise.resolve(mockedErrorResponseGameOffline);
};

const successTransformationMock: TransformationAction = (): TransformTranslationResponse => {
	return mockedTransformTranslationResponse;
};

export const translationMocks: {
	successTranslation: TranslationAction;
	failedTranslation: TranslationAction;
	generalFailError: TranslationAction;
} = {
	successTranslation: successTranslationMock,
	failedTranslation: failedTranslationMock,
	generalFailError: translationGeneralFailErrorMock
};

export const transformTranslationMocks: {
	successTransformation: TransformationAction;
} = {
	successTransformation: successTransformationMock,
};
