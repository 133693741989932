import {ValidateAction, ValidateResponseSuccess} from './validation';
import {ValidationErrorResponse} from '../response';
import {Error} from '../error';


export const successMock: ValidateAction = async (): Promise<ValidateResponseSuccess> => {
	const result: ValidateResponseSuccess = {};

	return Promise.resolve(result);
};

export const failMock: ValidateAction = async (): Promise<ValidationErrorResponse> => {
	const result: ValidationErrorResponse = {
		errors: new Map<string, Error[]>([
			['nickname', [ {code: 'be.api.portal.nickname_too_short', message: 'Nickname is too short'}]]
		]),
		nicknameSuggestions: [
			'Suggestion01',
			'Suggestion02',
			'Suggestion03',
			'Suggestion04',
			'Suggestion05'
		]
	};

	return Promise.resolve(result);
};

/**
 * Set of all available mocks
 */
export const validateMocks = {
	success: successMock,
	fail: failMock,
};
