import fetch from 'cross-fetch';
import {ValidateAction, ValidateRequest, ValidateResponse} from './validation';
import {addLPSLocaleHeader, sameOriginRequestPolicy} from '../actions';

/**
 * Validation against backend via {@link fetch}
 */
export class RemoteValidateAction {
	private readonly baseUrl: string | null;

	constructor(baseUrl: string | null) {
		this.baseUrl = baseUrl;
	}

	action: ValidateAction = async (validateRequest: ValidateRequest, locale: string): Promise<ValidateResponse> => {
		const base = this.baseUrl || '';

		const response: Response = await fetch(`${base}/api/validate`, {
			...sameOriginRequestPolicy,
			method: 'POST',
			headers: addLPSLocaleHeader({'Content-Type': 'application/onelps.validate.v2+json'}, locale),
			body: JSON.stringify(validateRequest)
		});

		if (!response.ok) {
			throw new Error('unexpected');
		}

		return response.json();
	};
}
