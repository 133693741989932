import {LoginErrorCodes} from './login';
import {ResetPasswordErrorCodes, PasswordForgottenVerifyErrorCodes} from './password-forgotten';

export type GenericErrorCodes = 'server-error' | 'be.api.auth.not-authenticated-play' | 'be.api.auth.not-authenticated-status' | 'be.api.lps.error';

export type ErrorCodes = GenericErrorCodes | LoginErrorCodes | PasswordForgottenVerifyErrorCodes | ResetPasswordErrorCodes | string;

/**
 * Interface definition for api error information returned by OneLPS. See ApiError class in BE.
 */
export interface Error {
	code: ErrorCodes;
	message: string;
}

/**
 * @deprecated
 * Please don't use it in newer actions anymore.
 * Will only be returned by already existing actions.
 * We agreed on a simple interface and structure {@link Error}.
 *
 * Generic object describing the reason
 */
export interface RichError {
	[key: string]: unknown;

	code: string;
	message: {
		en: string,
		translated: string
	};
	additionalInfo: Record<string, unknown>;
}

/**
 * Thrown when an action required body information, but they were not provided by the caller.
 */
export class RequiredBodyError extends Error {
	constructor() {
		super('Required body not provided');
		this.name = this.constructor.name;
	}
}
