export type MessageTypes = 'ERROR' | 'SUCCESS';

export interface Message {
	type: MessageTypes;
	code: string;
	description?: string | null;
	context: string;
}

export function isMessage(value: unknown): value is Message {
	if (!value || typeof value !== 'object' || Array.isArray(value)) {
		return false;
	}

	const castedValue: Message = value as Message;

	return castedValue.type !== undefined && castedValue.code !== undefined && castedValue.context !== undefined;
}
