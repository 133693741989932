import fetch from 'cross-fetch';
import {
	RegisterAction,
	RegisterRequest,
	RegisterResponse,
} from './registration';
import {addLPSLocaleHeader, sameOriginRequestPolicy} from '../actions';

/**
 * Register against backend via {@link fetch}
 */
export class RemoteRegisterAction {
	private readonly baseUrl: string | null;

	constructor(baseUrl: string | null) {
		this.baseUrl = baseUrl;
	}

	action: RegisterAction = async (registerRequest: RegisterRequest, locale: string): Promise<RegisterResponse> => {
		const base = this.baseUrl || '';

		const response: Response = await fetch(`${base}/api/register`, {
			...sameOriginRequestPolicy,
			method: 'POST',
			headers: addLPSLocaleHeader({'Content-Type': 'application/onelps.register.v2+json'}, locale),
			body: JSON.stringify(registerRequest)
		});

		if (!response.ok) {
			throw new Error('unexpected');
		}

		return await response.json();
	};
}
