/**
 * Sending entity representing login information entered by a player or provided by the landing page.
 */
export interface LoginRequest {
	username: string;
	password: string;
	useRememberMe: boolean;
}

export type LoginErrorCodes = 'be.api.auth.login-failed-id' | 'be.api.auth.login-failed-pw' | 'be.api.auth.login-failed-generic' | 'be.api.auth.params-incorrect-id-pw' | 'be.api.auth.params-incorrect-id' | 'be.api.auth.params-incorrect-pw';
