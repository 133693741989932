import fetch from 'cross-fetch';
import {ErrorResponse, TranslationResponse} from '../response';
import {TranslationAction} from './translation';
import {sameOriginRequestPolicy} from '../actions';

export class RemoteTranslationAction {
	private readonly baseUrl: string | null;

	constructor(baseUrl: string | null) {
		this.baseUrl = baseUrl;
	}

	action: TranslationAction = async (locale: string): Promise<TranslationResponse | ErrorResponse> => {
		const base: string = this.baseUrl || '';

		try {
			const response: Response = await fetch(`${base}/api/i18n/1/${locale}`,
				{
					...sameOriginRequestPolicy,
					method: 'GET',
					headers: {
						'Content-Type': 'application/json'
					},
				});
			return response.json();
		} catch (e: unknown) {
			throw new Error('unexpected');
		}
	};
}
