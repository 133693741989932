import {RedirectAction, sameOriginRequestPolicy} from '../actions';
import {ErrorResponse, RedirectResponse} from '../response';
import fetch from 'cross-fetch';

/**
 * Starts a game session after a player clicked on the play button.
 * Returns the game url a player should be redirected to.
 */
export class RemotePlayAction {
	private readonly baseUrl: string | null;

	constructor(baseUrl: string | null) {
		this.baseUrl = baseUrl;
	}

	action: RedirectAction<undefined> = async (): Promise<RedirectResponse | ErrorResponse> => {
		const base: string = this.baseUrl || '';

		try {
			const response: Response = await fetch(`${base}/api/play`, {
				...sameOriginRequestPolicy,
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				}
			});

			return response.json();
		} catch (e: unknown) {
			throw new Error('unexpected');
		}
	}
}
